import React from 'react';
import './share.scss';
import {TwitterShareButton, TwitterIcon, EmailShareButton, EmailIcon, FacebookShareButton, FacebookIcon } from 'react-share';

function Share()  {

  return (
    <div className="share-container">
    <FacebookShareButton className="share-venue" url="https://itmakessensetobelieve.com" >
      <FacebookIcon size={"3rem"} borderRadius={"8px"} />
    </FacebookShareButton>
    <TwitterShareButton className="share-venue" url="https://itmakessensetobelieve.com" title="It Makes Sense to Believe">
      <TwitterIcon size={"3rem"} borderRadius={"8px"} />
    </TwitterShareButton>
    <EmailShareButton  className="share-venue" 
      url="https://itmakessensetobelieve.com"
      subject="It Makes Sense to Believe"
      body="Because I care about you..."
    >
      <EmailIcon size={"3rem"} borderRadius={"8px"} />
    </EmailShareButton>
    <span className="show-hide"><br /></span><span style={{fontStyle: "italic"}}>Share this site with those you care about.</span>
  </div >
  )

}

export default Share;
