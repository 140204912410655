import React, { useEffect } from 'react';
//import ReactGA from 'react-ga';
import ReactGA from "react-ga4";
import { Container, Image, Card } from 'react-bootstrap'
import './app.scss';
import crucifix from './images/ItMakesSenseToBelieve.jpg';
import worddoc from './docs/Synopsis - It Makes Sense to Believe.docx';
import pdf from './docs/Synopsis - It Makes Sense to Believe.pdf';
import wordbook from './docs/Book - It Makes Sense to Believe.docx';
import pdfbook from './docs/Book - It Makes Sense to Believe.pdf';
import rose from './images/rose.svg';
import itMakesSense from './images/It Makes Sense.jpg';
import youTube from './images/YouTube_400.jpg';
import Share from './Share';

function App() {

  // new: 313435795
  // old: UA-168892917-2
  useEffect(() => {
    ReactGA.initialize('G-SRV34W3X3L');
    if (process.env.NODE_ENV === 'production') {
      //ReactGA.pageview(window.location.pathname);
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }
  }, []);

  const gaTrack = (category, action) => {
    if (process.env.NODE_ENV === 'production') {
        ReactGA.event({ category, action })
    }
  }

  return (
    <Container className="book-container">
      <Image src={crucifix} className="img-fluid rounded" />
      <header>
        <h2 className="text-center book-even" >
          even amidst horrific scandal...
        </h2>
        <h1 className="text-center book-title" >
          It Makes Sense to Believe
        </h1>
        <h2 className="text-center book-lit" >
          How God Has Lit A Path for Catholics
        </h2>
      </header>
      <main className="book-body" >
        <div className="book-section0">
          <Card bg="light">
            <Card.Body>
              <Card.Text>
                 <span style={{fontWeight: "bold"}}>Ralph Martin, S.T.D.</span>, 
                  a prominent Catholic teacher and author, endorsed 
                  my book, <a href="https://www.passingthefaithontolovedones.com/"
                      target="_blank" rel="noopener noreferrer"
                      style={{ textDecoration: "none", fontStyle: "italic" }}
                    >
                      Passing the Faith onto Loved Ones
                    </a>, on which this book, video and project (
                    <a href="https://www.intentionallycatholic.com/"
                      target="_blank" rel="noopener noreferrer"
                      style={{ textDecoration: "none"}}
                    >
                      Intentionally Catholic
                    </a>
                  ) are based. +Deacon John Beagan
              </Card.Text>
            </Card.Body>
          </Card>
        </div >

        <div className="book-section0">
          <div className="book-section1 book-the-book">For those who...</div>
          <div className="book-section2">
            Doubt or disbelieve...
          </div>
          <div id="" className="book-section2b" >
            or are lukewarm and disinterested...
          </div>
          <div className="book-description">
            This is a difficult and unpopular time to believe as a Catholic because horrible
            child abuse was committed by some clergy; Church sexual morality is out of sync
            with the culture; the Bible has been criticized for decades; and so forth.
            Yet, despite all the problems, this is also perhaps the clearest of times to
            believe next to standing beside Jesus during his many miracles and resurrection.
            Using evidence and common sense, this short book or video hopes to demonstrate why it is so
            reasonable to believe in God, his only beloved son Jesus, and the Church he historically
            established.
            </div>
          <div className="book-section2">But does it really matter?</div>
          <div className="book-description">
            <p>Suppose the crucifixion of God's only beloved son Jesus is true.
            How will it go when our life ends, and we must stand before God
              after neglecting and forgetting his son?</p>
            <p>It is my hope and prayer that the case presented in this book and video
            will help us believe and be prepared to stand before
            God the Father, so when the time comes we may pray like Simeon,
            "Lord, now your servant is ready to go to you in peace." (Luke 2:29)
              </p>
          </div>
        </div>

        <div className="book-section0">
          <div className="book-section1 book-the-video">The Video</div>
          <div className="book-section2">YouTube</div>
          <div className="book-description">
            Based on the book, may this video help strengthen your belief and zeal. 
            It is also segmented into 12 short videos.
          </div>
          <div className="book-links">
            <a href="https://youtu.be/mV3tP0w3Edc"
                target="_blank" rel="noopener noreferrer"
                onClick={() => gaTrack('Resources', 'TheVideo-YouTube')}
              >
              <Image src={youTube} 
                    className="img-fluid rounded" 
                    title="It Makes Sense to Believe - The Video"
                    />
            </a>
            <div className="book-span">(83 minutes)</div>
          </div>

          <div className="book-section2">Short Videos</div>
          <div className="book-links">

            {/* From Someone Who Loves You */}
            <a href="https://youtu.be/GQb7dBQy6lM"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'From-Someone')}
              style={{ fontWeight: "bold", fontStyle: "italic" }}
            >
              From Someone Who Loves You
            </a>
            <span className="book-span2"> (11&nbsp;min), </span>

            {/* Crosses - Rosie */}
            <a href="https://www.youtube.com/watch?v=hRQq8VR7Ne4"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'crosses')}
            >
              Crosses - Rosie
            </a>
            <span className="book-span2"> (4&nbsp;min), </span>

            {/* 1 */}
            <a href="https://youtu.be/f9Kn_ZVVARI"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', '01-should-we')}
            >
              1-Should We Care?
            </a>
            <span className="book-span2"> (9&nbsp;min), </span>

            {/* 2 */}
            <a href="https://youtu.be/qAPaOiQOKHE"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', '02-truth')}
            >
              2-Importance of Truth
            </a>
            <span className="book-span2"> (3&nbsp;min), </span>

            {/* 3 */}
            <a href="https://youtu.be/egVKsIpmIqE"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', '03-is-god-dead')}
            >
              3-Is God Dead?
            </a>
            <span className="book-span2"> (3&nbsp;min), </span>

            {/* 4 */}
            <a href="https://youtu.be/m1BMtAtO23o"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', '04-reveal')}
            >
              4-Did God Reveal Himself?
            </a>
            <span className="book-span2"> (11&nbsp;min), </span>

            {/* 5 */}
            <a href="https://youtu.be/rrfp4G5s6mg"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', '05-Love')}
              style={{ fontWeight: "bold", fontStyle: "italic" }}
            >
              5-Love
            </a>
            <span className="book-span2"> (5&nbsp;min), </span>

            {/* 6 */}
            <a href="https://youtu.be/JHsuE3PYMq8"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', '06-evidence')}
            >
              6-Evidence
            </a>
            <span className="book-span2"> (4&nbsp;min), </span>

            {/* 7 */}
            <a href="https://youtu.be/JUr7l7o6vG8"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', '07-Evil')}
              style={{ fontWeight: "bold", fontStyle: "italic" }}
            >
              7-Evil
            </a>
            <span className="book-span2"> (20&nbsp;min), </span>

            {/* 8 */}
            <a href="https://youtu.be/0Fj5wAPt8UQ"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', '08-suffering-death')}
            >
              8-Suffering &amp; Death
            </a>
            <span className="book-span2"> (9&nbsp;min), </span>

            {/* 9 */}
            <a href="https://youtu.be/16lbIYsre3Y"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', '09-little-faith')}
            >
              9-A Little Faith Is Needed
            </a>
            <span className="book-span2"> (8&nbsp;min), </span>

            {/* 10 */}
            <a href="https://youtu.be/8wDDdkTDI8M"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', '10-sex')}
            >
              10-Sex, Sin &amp; Scandal
            </a>
            <span className="book-span2"> (7&nbsp;min), </span>

            {/* 11 */}
            <a href="https://youtu.be/BCjCYcULNMc"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', '11-women')}
            >
              11-Women's Ordination
            </a>
            <span className="book-span2"> (5&nbsp;min), </span>

            {/* 12 */}
            <a href="https://youtu.be/C84uxYDg9mU"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', '12-making-it')}
            >
              12-Making It into Heaven
            </a>
            <span className="book-span2"> (9&nbsp;min), </span>

            {/* Salvation: Church in Crisis */}
            <a href="https://youtu.be/PHJmtRYd4XA"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'Salvation-Crisis')}
              style={{ fontWeight: "bold", fontStyle: "italic" }}
            >
              Salvation: Church in Crisis
            </a>
            <span className="book-span2"> (21&nbsp;min)</span>

          </div>
        </div>

        <div className="book-section0">
          <div className="book-section1 book-back-cover">The Book</div>
          <div className="book-section2">Amazon</div>
          <div className="book-links">
            <a href="https://www.amazon.com/dp/B08BWFKBDK?ref_=pe_3052080_397514860"
                target="_blank" rel="noopener noreferrer"
                onClick={() => gaTrack('Resources', 'CoverClickItMakesSense')}
                style={{ textDecoration: "none" }}>
                <Image src={itMakesSense} 
                  className="img-fluid rounded" 
                  title="It Makes Sense to Believe"
                  />
              </a>
          </div>

          <div className="book-button-outer" >
            <div className="book-links">
              <a href="https://www.amazon.com/dp/B08BWFKBDK?ref_=pe_3052080_397514860"
                target="_blank" rel="noopener noreferrer"
                onClick={() => gaTrack('Resources', 'Amazon-ItMakesSenseButton')}
                style={{ textDecoration: "none" }}>
                <span
                  className="book-button"
                  style={{ backgroundColor: "#088c9b" }}
                >
                  Amazon Books
              </span>
              </a>
              <div className="book-price">(Kindle 4.95 US, Paperback 7.95 US)</div>
            </div>
          </div>

          <div className="book-section2">YouTube</div>
          <div className="book-links">
            <a href="https://youtu.be/MpITLJFQXM0"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'Book-YouTube')}
            >
              It Makes Sense to Believe</a>
            <div className="book-span">(Narration: 2 hours, 32 minutes)</div>
          </div>
          <div className="book-section2">Manuscript</div>
          <div className="book-links">
            <span className="book-link" >
              <a href={wordbook} onClick={() => gaTrack('Resources', 'Book-Word')}
              >Word doc</a></span>
            <span className="book-link" >
              <a href={pdfbook}
                target="_blank" rel="noopener noreferrer"
                onClick={() => gaTrack('Resources', 'Book-Pdf')}
              >Pdf</a></span>
            <span><a href="https://docs.google.com/document/d/1C6Gn0AiIKeMIf_WeryoJzOQDSXyLnEFPjlNWUZuFgTg/edit?usp=sharing"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'Book-GoogleDoc')}
            >Google doc</a></span>
          </div>
        </div>

        <div className="book-section0">
          <div className="book-section1 book-synopsis">Synopsis</div>
          <div className="book-description">
            Pressed for time? This lays out main points of book
            but with fewer details and anecdotes.
          </div>
          <div className="book-section2">YouTube</div>
          <div className="book-links">
            <a href="https://www.youtube.com/watch?v=uY__BlN_eJU"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'Synopsis-YouTube')}
            >
              It Makes Sense to Believe</a>
            <div className="book-span">(36 minutes)</div>
          </div>
          <div className="book-section2">Manuscript</div>
          <div className="book-links">
            <span className="book-link" >
              <a href={worddoc} onClick={() => gaTrack('Resources', 'Synopsis-Word')}
              >Word doc</a></span>
            <span className="book-link" >
              <a href={pdf}
                target="_blank" rel="noopener noreferrer"
                onClick={() => gaTrack('Resources', 'Synopsis-Pdf')}
              >Pdf</a></span>
            <span><a href="https://docs.google.com/document/d/1VoZO74t1iP6ZLlJFrGpvDXruRGXdYKr9SPcn2FmzyII/edit?usp=sharing"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'Synopsis-GoogleDoc')}
            >Google doc</a></span>
          </div>
        </div>

        <div className="book-section0">
          <div className="book-section1 salvation-crisis" >
            Salvation
          </div>
          <div className="book-description" style={{fontStyle: "italic", fontSize: "150%"}}>
            An Intuitive Case for Jesus&apos; Dire Words
          </div>
          <div className="book-description">
            Contrary to popular &ldquo;feelings,&rdquo; this article considers 
            the accuracy of Jesus&apos; words in light of how much God loves us, 
            the crucifixion of his only beloved son, and the intimate 
            reality of heaven. (Read or listen.) <a
             href="https://www.IntentionallyCatholic.com/Dire"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'MakingDire')}
               >
              IntentionallyCatholic.com/<b>Dire</b>
            </a>.
          </div>
        </div>

        <div className="book-section0" style={{ marginBottom: "3rem" }}>
            <div className="book-section1 book-back-cover" >
              Intentionally Catholic
            </div>
            <div className="book-description">
              Resources for clergy and laity to shore up the faith 
              of parishioners and help pass the faith on to loved ones.
            </div>
            <div className="book-button-outer" >
              <div className="book-links">
                <a href="http://www.IntentionallyCatholic.com"
                  target="_blank" rel="noopener noreferrer"
                  onClick={() => gaTrack('Resources', 'Intentionally-Main')}
                  style={{ textDecoration: "none" }}>
                  <span
                    className="book-button"
                    style={{ backgroundColor: "#088c9b" }}
                  >
                    Goto Main Page
                  </span>
                </a>
              </div>
            </div>
          </div>

          <div className="book-section0 margin-top" style={{marginBottom: "4rem"}}>
          <div className="book-section1 book-rosie" >
            Rosie
          </div>
          <div className="book-description">
            Five days before Christmas 2019, at 5:00am, Charlie discovered his daughter's lifeless body. Rosie was a 
            perfectly healthy 2-year-old. She was Laura and Charlie's only child, and our only grandchild. In the following
            links, they discussed what happened, and how their Catholic faith pulled them through.
          </div>

          <div className="book-description">
            In this secular podcast, <b>Laura and Charlie</b> told their story and provided some raw details. The 
            interviewer was excellent. They also wove in how their faith helped. <a
             href="https://open.spotify.com/episode/1sP0f7b2Hky0Vqa4aKcyE8"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'rosie1')}
              style={{ fontStyle: "italic" }} >
              Tough Mudder Podcast
            </a>. 
          </div>

          <div className="book-description">
            In this video, <b>Laura</b> spoke to 300 women at Long Beach Catholic Community 
            during an evening of recollection. Her presentation begins after the 22 minute mark  
            and they watch a 2 minute video which is not visible. <a
             href="https://www.youtube.com/watch?v=zAQtXncFZmU"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'rosie2')}
              style={{ fontStyle: "italic" }} >
              A Rose in Heaven | BC Ladies Event
            </a>. 
          </div>

          <div className="book-description">
            In this video, <b>Charlie</b> spoke to 50 Beach Catholic men during a day of retreat. <a
             href="https://www.youtube.com/watch?v=SGwfpKVPto8"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'rosie3')}
              style={{ fontStyle: "italic" }} >
              Charlie's Presentation
            </a>. 
          </div>
        </div>

        <Share />

        <div className="book-section0">
          <div className="book-section1 contact-info">Contact Information</div>
          <div className="book-description">
            Please contact me at:
            <br className="show-hide" /> <a href="mailto: DeaconJohnBeagan@gmail.com">DeaconJohnBeagan@gmail.com</a>
            <br className="show-hide" /> if you have any questions or feedback. 
            <br />God bless!
            <div className="signature">
              Deacon John Beagan
            </div>
            <div className="serving">
              Serving Sacred Heart &amp; Saint Patrick parishes in Watertown, MA
            </div>
            <div className="serving">
              Archdiocese of Boston
            </div>
          </div>
        </div>

      </main>

      <footer className="text-center book-footer">
        <div>
          <a href="https://www.IntentionallyCatholic.com"
            target="_blank" rel="noopener noreferrer"
            onClick={() => gaTrack('Resources', 'IntentionallyCatholicLink')}
            style={{ fontStyle: "italic", fontWeight: "400" }}>
            Intentionally Catholic .com
          </a>
        </div>
        <div className="rosie">
          <a href="https://www.RosieBeagan.com"
            target="_blank" rel="noopener noreferrer"
            onClick={() => gaTrack('Resources', 'RosieLink')}
            style={{ fontStyle: "italic", fontWeight: "500" }}>
            <Image src={rose} 
            className="img-fluid rounded" 
            title="Rose Elizabeth Foundation" 
            width="40px"
          />
            Rosie
          </a>
        </div>
      </footer>

    </Container>
  );
}

export default App;
